module.exports = {
  city: [
    {
      city_code: "1",
      city: "北京",
      state: "1",
    },
    {
      city_code: "2",
      city: "天津",
      state: "1",
    },
    {
      city_code: "3",
      city: "河北",
      state: "1",
    },
    {
      city_code: "4",
      city: "山西",
      state: "1",
    },
    {
      city_code: "6",
      city: "辽宁",
      state: "1",
    },
    {
      city_code: "7",
      city: "吉林",
      state: "1",
    },
    {
      city_code: "8",
      city: "黑龙江",
      state: "1",
    },
    {
      city_code: "9",
      city: "上海",
      state: "1",
    },
    {
      city_code: "10",
      city: "江苏",
      state: "1",
    },
    {
      city_code: "11",
      city: "浙江",
      state: "1",
    },
    {
      city_code: "12",
      city: "安徽",
      state: "1",
    },
    {
      city_code: "13",
      city: "福建",
      state: "1",
    },
    {
      city_code: "14",
      city: "江西",
      state: "1",
    },
    {
      city_code: "15",
      city: "山东",
      state: "1",
    },
    {
      city_code: "16",
      city: "河南",
      state: "1",
    },
    {
      city_code: "17",
      city: "湖北",
      state: "1",
    },
    {
      city_code: "18",
      city: "湖南",
      state: "1",
    },
    {
      city_code: "19",
      city: "广东",
      state: "1",
    },
    {
      city_code: "21",
      city: "海南",
      state: "1",
    },
    {
      city_code: "22",
      city: "重庆",
      state: "1",
    },
    {
      city_code: "23",
      city: "四川",
      state: "1",
    },
    {
      city_code: "24",
      city: "贵州",
      state: "1",
    },
    {
      city_code: "25",
      city: "云南",
      state: "1",
    },
    {
      city_code: "27",
      city: "陕西",
      state: "1",
    },
    {
      city_code: "28",
      city: "甘肃",
      state: "1",
    },
    {
      city_code: "29",
      city: "青海",
      state: "1",
    },
    {
      city_code: "30",
      city: "宁夏",
      state: "1",
    },
    {
      city_code: "5",
      city: "内蒙古",
      state: "1",
    },
    {
      city_code: "20",
      city: "广西",
      state: "1",
    },
    {
      city_code: "31",
      city: "新疆",
      state: "1",
    },
    {
      city_code: "26",
      city: "西藏",
      state: "1",
    },
  ],
  tipList: [
    {
      id: "11",
      name: "C9",
      type: "0",
    },
    {
      id: "5",
      name: "985",
      type: "0",
    },
    {
      id: "6",
      name: "211",
      type: "0",
    },
    {
      id: "7",
      name: "双一流建设高校",
      type: "0",
    },
    {
      id: "9",
      name: "教育部直属",
      type: "0",
    },
    {
      id: "12",
      name: "研究生院",
      type: "0",
    },
    {
      id: "10",
      name: "其他部属",
      type: "0",
    },
    {
      id: "13",
      name: "国家示范",
      type: "0",
    },
    {
      id: "14",
      name: "国家骨干",
      type: "0",
    },
    {
      id: "15",
      name: "双高计划",
      type: "0",
    },

  ],
  typeList: [
    {
      id: "17",
      name: "财经类",
    },
    {
      id: "18",
      name: "理工类",
    },
    {
      id: "19",
      name: "农林类",
    },
    {
      id: "20",
      name: "综合类",
    },
    {
      id: "21",
      name: "政法类",
    },
    {
      id: "22",
      name: "师范类",
    },
    {
      id: "23",
      name: "语言类",
    },
    {
      id: "24",
      name: "医药类",
    },
    {
      id: "26",
      name: "民族类",
    },
    {
      id: "27",
      name: "艺术类",
    },
    {
      id: "28",
      name: "体育类",
    },
    {
      id: "29",
      name: "军事类",
    },
  ],
  batchList: [
    {
      id: "1",
      name: "本科一批",
    },
    {
      id: "2",
      name: "本科二批",
    },
    {
      id: "3",
      name: "高职高专批",
    },
    {
      id: "6",
      name: "提前录取院校",
    },

    {
      id: "4",
      name: "国家专项",
    },
    {
      id: "5",
      name: "地方专项",
    },
  ],
  natureList: [
    {
      id: "1",
      name: "公办",
    },
    {
      id: "2",
      name: "民办",
    },
    {
      id: "3",
      name: "中外合作办学",
    },
    {
      id: "4",
      name: "内地与港澳台地区合作办学",
    },
  ]
}

